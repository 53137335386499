export default [
  {
    title: 'Obras',
    route: 'dashboard',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Trabajos',
    route: 'jobs',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Tareas',
    route: 'tasks',
    icon: 'CalendarIcon',
  },
  {
    title: 'Parte de horas',
    route: 'partesOperario',
    icon: 'ClockIcon',
  },
  {
    title: 'Trazabilidad',
    route: 'traceabilities',
    icon: 'ActivityIcon',
  },
  {
    title: 'Gestión',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Usuarios',
        route: 'users',
      },
      {
        title: 'Clientes',
        route: 'clients',
      },
      {
        title: 'Categorías',
        route: 'categories',
      },
      {
        title: 'Departamentos',
        route: 'departments',
      },
      {
        title: 'Maquinas',
        route: 'machines',
      },
      {
        title: 'Calendario laboral',
        route: 'calendario',
      },
      {
        title: 'Datos empresa',
        route: 'config',
      },
    ],
  },
]
