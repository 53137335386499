export default [
  {
    title: 'Inicio',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Partes de horas',
    route: 'parts',
    icon: 'ServerIcon',
  },
  {
    title: 'Obras',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'Listado obras',
        route: 'obras',
      },
      {
        title: 'Trabajos',
        route: 'jobs',
      },
    ],
  },
]
